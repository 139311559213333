@import "../../../../style/mixins.scss";

.storageSelectorContainer {
  margin-top: -0.8rem;
  @include medium-screen {
    grid-column: span 2;
  }
  .storageSelector {
    padding: 0 1.6rem 0.8rem 0;
    margin-bottom: 0;
  }
  .selectField {
    padding-right: 6rem !important;
  }
}
