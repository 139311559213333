@import "../../../../style/colors.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/mixins.scss";

.wrapper {
  overflow-x: auto;
}

.noErrorField {
  p {
    display: none;
  }
}

.page {
  margin: 0;

  .button {
    display: inline-block;
  }
}

.card {
  border-top: 1px solid $borderColorLight;
  padding: $spacing-s;

  .header {
    display: flex;

    .left {
      flex: 1;
    }

    .right {
      flex: 1;
      text-align: right;
    }

    .status {
      font-weight: $fontWeightMedium;

      &.status20 {
        color: $lightGrey;
      }

      &.status30 {
        color: $blue;
      }

      &.status50 {
        color: $orange;
      }
    }
  }

  .bold {
    font-weight: $fontWeightMedium;
  }

  .row {
    display: flex;

    .grow {
      flex: 1;
    }
  }

  .label {
    font-weight: $fontWeightLight;
    padding-right: 3px;
  }
}

.listHead {
  border-bottom: 1px solid $borderColorLight;
  padding: $spacing-s 0;
  margin: $spacing-s 0;
}

.title {
  font-size: $fontSizeMedium;
  font-weight: $fontWeightMedium;
  margin-bottom: $spacing-s;
}

.allocations {
  li {
    padding: $spacing-s 0;
    border-bottom: 1px solid $borderColorLight;

    &.disabled {
      color: $lightGrey;
    }
  }
}

.allocation {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 28px 1fr 1fr 1fr;
  grid-template-areas:
    "checkbox title title location-quantity"
    "checkbox item asset quantity";

  .titleSection {
    grid-area: title;
  }
  .locationQuantity {
    grid-area: location-quantity;
    display: flex;
    justify-content: flex-end;
  }
  .itemInfo {
    grid-area: item;
  }
  .assetInfo {
    grid-area: asset;
  }
  .quantityInfo {
    grid-area: quantity;
  }
  @include small-screen {
    grid-template-areas:
      "checkbox title title title"
      "checkbox location-quantity location-quantity location-quantity"
      "checkbox item item item"
      "checkbox asset asset asset"
      "checkbox quantity quantity quantity";
  }
}

.actions {
  display: flex;
  margin-top: $spacing-l;

  .button {
    margin-right: $spacing-l;
  }

  @include small-screen {
    display: flex;
    flex-direction: column;
    .button {
      margin-bottom: $spacing-s;
    }
  }
}
