@import "../../../style/colors.scss";
@import "../../../style/spacing.scss";

.counter {
  display: flex;
  align-items: center;
  .roundBtn {
    margin: 0 $spacing-s;
    &:disabled {
      opacity: 0.4;
      color: $lightGrey;
    }
  }

  .count {
    appearance: none;
    height: 4rem;
    width: 5rem;
    text-align: center;
    border: 1px solid $lightGrey;

    &:focus {
      outline: none;
      border: 2px solid $affordance;
      box-shadow: none;
    }
  }

  /* Hide native arrows in numberfield */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
