@import "../../../style/colors.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";
@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";

// 1.5 = line height
$filterHeaderHeight: calc(#{$spacing-m} * 2 + #{$baseFontSize} * 1.5);

.section {
  padding: 1rem 2rem;
  width: 12rem;
}

.header {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin: 0;
  cursor: pointer;

  p {
    display: flex;
    padding: 1rem 2rem;
    margin: 0;
    font-size: $fontSizeLarge;
  }
}

.icon {
  margin: auto 1rem auto 0;
}

.active {
  min-height: 0;
  height: auto;
}

.filterSection {
  border-bottom: 1px solid $borderColorLight;

  &:last-child .list .item:last-child {
    margin-bottom: $mobileMenuHeight;
  }

  .title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: $primaryLight2;
    padding: $spacing-m $spacing-xl;
    margin: 0;
    height: $filterHeaderHeight;

    .arrow {
      display: flex;
    }
  }

  .list {
    padding: $spacing-m $spacing-xl;
    min-height: 0;
    height: calc(100% - #{$filterHeaderHeight});

    &.overflowScroll {
      overflow-y: scroll;
    }

    .item {
      margin: $spacing-l 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .radioButtons {
      label {
        margin: $spacing-s 0;
      }
      &.vertical {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

button.ghost {
  padding: 0 1rem;
  background: none;
  border: none;
  outline: none;

  &:hover {
    font-weight: bold;
  }
}

.filter {
  margin-top: 1.5rem;
}
