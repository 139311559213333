@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.steps {
  width: max-content;
  display: grid;
  margin-bottom: $spacing-s;

  @include small-screen {
    width: 100%;
    margin-bottom: $spacing-m;
  }

  .step {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: silver;
    font-size: $fontSizeSmall;
    position: relative;
    padding-right: $spacing-l;
    @include small-screen {
      padding: 0;
    }

    &:not(:first-child) {
      &::before {
        content: "";
        height: 2px;
        position: absolute;
        width: 100%;
        right: 50%;
        top: $spacing-m;
        background: silver;
      }
    }

    .indicator {
      border: 2px solid silver;
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: $fontWeightMedium;
      z-index: 1;
      background: $white;
    }

    .indicatorText {
      margin: $spacing-s 0;
      @include small-screen {
        display: none;
      }
    }

    &.currentStep {
      color: $primary;
      &::before {
        background: $primary;
      }

      .indicator {
        color: $white;
        border-color: $primary;
        background: $primary;
      }
    }
    &.finishedStep {
      &::before {
        background: $primary;
      }
      .indicator {
        color: $primary;
        border-color: $primary;
      }
      &:hover {
        color: $primary;
      }
    }
  }
}

.content {
  border: 1px solid $borderColorLight;
  border-radius: $borderRadiusSmall;
  padding: $spacing-xl;
  padding-top: $spacing-l;

  @include small-screen {
    padding: $spacing-l $spacing-m;
  }

  .subTitle {
    @extend %sectionTitle;
    margin-bottom: $spacing-l;
  }
}
