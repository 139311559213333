@import "../../../../style/spacing.scss";
.buttonCol {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin-top: -6.7rem;
  input[type="number"] {
    width: 3.5rem;
    height: 3rem;
    font-size: 14px;
  }
}
.orderButton {
  button {
    margin-top: 1rem;
    font-size: 12px;
    height: 3rem !important;
    min-width: 10rem !important;
  }
}
