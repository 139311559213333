@import "../../../../style/mixins.scss";
@import "../../../../style/sizes.scss";
@import "../../../../style/spacing.scss";

.address {
  margin: $spacing-l;
  font-style: normal;
  min-width: 20rem;
  p {
    margin: 0;
  }
}
