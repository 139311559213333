@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/sizes.scss";
@import "../../../../style/spacing.scss";

.cartLink {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  &:hover {
    color: $affordance;
    text-decoration: none;
  }
  .text {
    @include small-screen {
      display: none;
    }
  }
  .icon {
    margin-left: $spacing-m;
  }
}
