@import "../../../../../style/spacing.scss";
@import "../../../../../style/typography.scss";
@import "../../../../../style/colors.scss";

.selectField {
  width: 95%;
}

.errorMessage {
  font-size: $fontSizeSmall;
  min-height: $spacing-l;
  margin-bottom: 0;
  color: $error;
}
