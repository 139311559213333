@import "../../../../style/spacing.scss";
@import "../../../../style/colors.scss";

.buttonBase {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
}

.information {
  padding: 0 $spacing-l $spacing-l;
  .value {
    margin-bottom: 0;
    line-height: 1.8;
  }
}
