@import "../../style/colors.scss";
@import "../../style/spacing.scss";

.messageLink {
  color: inherit;
  display: flex;
  align-items: center;
  padding: 0 $spacing-m;

  border-right: 1px solid $borderColorLight;

  &:hover {
    color: $affordance;
    text-decoration: none;
  }

  .icon {
    margin-left: $spacing-m;
  }
}

.selected {
  color: $affordance;
}
