@import "../../../../style/typography.scss";
@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";

.page {
  margin: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.title {
  font-weight: $fontWeightMedium;
  font-size: $fontSizeMedium;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include small-screen {
    grid-template-columns: 1fr;
  }
}

.status {
  font-weight: $fontWeightMedium;

  &.status20 {
    color: $red;
  }
  &.status30 {
    color: $lightGrey;
  }
  &.status40 {
    color: $greenDark;
  }
}
