@import "../../../style/spacing.scss";
@import "../../../style/colors.scss";
@import "../../../style/typography.scss";
@import "../../../style/mixins.scss";
@import "../../../style/z-index.scss";

%partialBorder {
  content: "";
  display: block;
  position: absolute;
  width: 40%;
  height: 100%;
  border-width: 2px;
  border-color: $darkWhite;
  border-top-style: solid;
  border-bottom-style: solid;
  z-index: $pseudoElement;
}

%content {
  flex-grow: 1;
  flex-basis: 0;
}

.container {
  margin-top: $spacing-xxl;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-height: 33vh;

  @include medium-screen {
    margin-top: 0;
    grid-template-columns: 1fr 1fr;
    gap: 4.8rem;
  }

  @include small-screen {
    grid-template-columns: 1fr;
  }

  &::before {
    @extend %partialBorder;
    border-left-style: solid;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    @include medium-screen {
      display: none;
    }
  }

  &::after {
    @extend %partialBorder;
    right: 0px;
    border-right-style: solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    @include medium-screen {
      display: none;
    }
  }

  .contentContainer {
    @extend %content;
    padding: $spacing-xxl;

    @include medium-screen {
      padding: 0;
    }

    @include small-screen {
      padding: 0;
    }
  }

  .textContainer {
    .sectionTitle {
      @extend %sectionTitle;
    }
    .text {
      white-space: pre-wrap;
    }
  }

  .infoContainer {
    @include medium-screen {
      grid-column: 1;
      grid-row: 2;
    }
  }

  .image {
    display: flex;

    @include medium-screen {
      align-self: start;
    }
  }
}
