@import "../../../style/mixins.scss";
@import "../../../style/colors.scss";

.image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productImage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.missingImage {
  @extend .productImage;
  background-color: #f3f3f3;
  color: $lightGrey;
  width: 100%;
}

.notOrderable {
  position: absolute;
  top: 0px;
  left: 0px;
  color: $fontColor;
  background-color: $lightYellow;
  padding: 0.3rem;
}
