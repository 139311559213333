@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/z-index.scss";

%partialBorder {
  content: "";
  display: block;
  position: absolute;
  width: 40%;
  height: 100%;
  border-width: 2px;
  border-color: $darkWhite;
  border-top-style: solid;
  border-bottom-style: solid;
  z-index: $pseudoElement;
}

%content {
  flex-grow: 1;
  flex-basis: 0;
}

.container {
  margin-top: -1rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  margin-left: 6rem;
  margin-right: 6rem;

  @include medium-screen {
    margin-top: 0;
    grid-template-columns: 1fr;
    gap: 4.8rem;
    margin-left: 0;
    margin-right: 0;
  }

  @include small-screen {
    grid-template-columns: 1fr;
  }

  &::before {
    @extend %partialBorder;
    border-left-style: solid;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    @include medium-screen {
      display: none;
    }
  }

  &::after {
    @extend %partialBorder;
    right: 0px;
    border-right-style: solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    @include medium-screen {
      display: none;
    }
  }

  .image {
    display: flex;

    @include medium-screen {
      align-self: start;
    }
  }

  .imageContainer {
    margin-top: $spacing-start-logo;

    @include medium-screen {
      margin-top: 0;
      width: 80%;
      align-self: start;
    }

    @include small-screen {
      width: auto;
      justify-self: center;
    }
  }
}

.sectionTitle {
  @extend %sectionTitle;
}

.bankIdWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
  margin: 3rem 3rem 0 3rem;

  @include medium-screen {
    flex-direction: column;
    align-items: center;
  }
}

.bankIdContentText {
  list-style-type: decimal;
  flex: 1 1;
  text-align: left;
  min-width: 200px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.textCentered {
  align-items: center;
  text-align: center;
}

.withGap {
  gap: 6rem;
}

.bankIdContentQR {
  flex: 0 0 auto;
  min-width: 128px;
  display: flex;
  text-align: left;
  gap: 6rem;
}

.authenticationContainer {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.bankIDContainerTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 500;
}

.icon {
  margin-right: 12px;
  height: 3em;
  fill: currentColor;
}

.titleIcon {
  height: 7em;
}

.spinnerPlaceholder {
  min-height: 290px;
}

.cancelContent {
  padding: 20px 0px;
  cursor: pointer;
}

.textAlignLeft {
  width: 300px;
  text-align: left;
}

.logo {
  display: none;

  @include medium-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    max-width: 100%;
    margin-left: 6rem;
    margin-right: 6rem;
    margin-bottom: 6rem;
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
}
