@import "../../../style/colors.scss";
@import "../../../style/defaults.scss";
@import "../../../style/spacing.scss";

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 0 auto;

  .currentPageInput {
    width: 40px;
    &:focus {
      outline: none;
    }
  }

  .pageButton {
    @extend %defaultBtnProps;
    width: 4rem;
    height: 4rem;
    background: none;
    border: 0;
  }

  .pageInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 $spacing-m;
    .currentPageInput {
      text-align: center;
      border: 1px solid $borderColorLight;
      padding: 0 $spacing-xs;
      color: currentColor;
      width: fit-content;
      max-width: 7rem;
    }

    .totalPages {
      margin: 0;
      .text {
        margin: 0 $spacing-s;
      }
    }
  }

  /* Hide native arrows in numberfield */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
