@import "../../style/mixins.scss";
@import "../../style/spacing.scss";
@import "../../style/colors.scss";
@import "../../style/typography.scss";

.generalSearch {
  display: flex;
  align-items: baseline;
  padding-bottom: $spacing-l;

  & > * {
    margin: 0 $spacing-m;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.label {
  margin-bottom: $spacing-s;
  span {
    margin-right: $spacing-s;
  }

  .labelTitle {
    font-weight: $fontWeightLight;
  }
}

.results {
  border-top: 1px solid $borderColorLight;
  .item {
    border-bottom: 1px solid $borderColorLight;
    padding: $spacing-m;
    .section {
      display: grid;
      grid-template-columns: 2fr;
    }
  }
}

@media (max-width: 600px) {
  .button {
    min-width: 6rem !important;
  }
}
