@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";
@import "../../../style/mixins.scss";

.wrapper {
  overflow-x: auto;
}

.noErrorField {
  p {
    display: none;
  }
}

.page {
  margin: 0;
}

.filter {
  margin: $spacing-m 0;
}

.generalSearch {
  display: flex;
  align-items: center;
  padding-bottom: $spacing-l;

  & > * {
    margin: 0 $spacing-m;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.card {
  border-top: 1px solid $borderColorLight;
  padding: $spacing-s;

  &:last-child {
    border-bottom: 1px solid $borderColorLight;
  }
  .content {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;

    .deviationInfo {
      margin-right: $spacing-s;
    }
    .right {
      display: flex;
      justify-content: flex-end;
    }

    @include medium-screen {
      grid-template-columns: 1fr;
      .right {
        justify-content: flex-start;
      }
    }
  }
  .status {
    font-weight: $fontWeightMedium;

    &.status20 {
      color: $red;
    }
    &.status30 {
      color: $lightGrey;
    }
    &.status40 {
      color: $greenDark;
    }
  }

  .bold {
    font-weight: $fontWeightMedium;
  }

  .label {
    font-weight: $fontWeightLight;
    padding-right: 3px;
  }
}

.listHead {
  border-bottom: 1px solid $borderColorLight;
  padding: $spacing-s 0;
  margin: $spacing-s 0;
}

.title {
  font-size: $fontSizeMedium;
  font-weight: $fontWeightMedium;
  margin-bottom: $spacing-s;
}

.allocations {
  li {
    display: flex;
    padding: $spacing-s 0;
    border-bottom: 1px solid $borderColorLight;
  }
}

.allocation {
  flex: 1;

  .title {
    justify-content: space-between;
  }
}

.actions {
  margin-top: $spacing-l;

  & > * {
    margin-right: $spacing-l;
  }
}

@media (max-width: 700px) {
  .button {
    min-width: 6rem !important;
  }
}
