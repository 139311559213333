@import "../../../style/colors.scss";
@import "../../../style/spacing.scss";
@import "../../../style/mixins.scss";

.modal {
  & > div > div {
    max-width: 90% !important;
  }
}

.results {
  border-top: 1px solid $borderColorLight;
  padding: $spacing-m $spacing-m $spacing-s $spacing-m;
  display: grid;
  grid-template-columns: 1.5fr 1fr 0.5fr;

  @include medium-screen {
    grid-template-columns: 1fr;
  }
}

.orderInstruction {
  padding-left: $spacing-m;
}
