@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";
@import "../../../style/mixins.scss";

.modal {
  @include not-small-screen {
    max-height: min(90vh, 70rem) !important;
  }
  padding-bottom: $spacing-m;

  .title {
    font-size: 1.8rem;
    margin-bottom: 0;
  }

  .product {
    display: grid;
    grid-template-columns: 1fr 4fr;
    column-gap: $spacing-m;
    row-gap: $spacing-m;
    margin-bottom: $spacing-xxl;

    .header {
      margin-top: 0;
      font-weight: $fontWeightMedium;
    }

    .productArea {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      .header {
        margin-top: 0;
        font-weight: $fontWeightNormal;
      }
      .value {
        margin-top: 0;
        font-weight: $fontWeightMedium;
      }
    }

    .accessories {
      grid-column: 2;
      display: grid;
      grid-template-columns: 1fr 2fr;
      border-bottom-style: solid;
      border-width: 1px;
      align-items: center;
    }

    p {
      margin: $spacing-s 0;
    }
  }
}
