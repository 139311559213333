@import "../../../style/typography.scss";
@import "../../../style/spacing.scss";
@import "../../../style/mixins.scss";

.contentTitle {
  @extend %sectionTitle;
}

.selectAll {
  margin: $spacing-s $spacing-l;
}
.results {
  border-top: 1px solid $borderColorLight;

  .item {
    display: grid;
    grid-template-columns: 28px 1fr;
    grid-template-areas:
      "checkbox title"
      "checkbox info";
    border-bottom: 1px solid $borderColorLight;
    padding: $spacing-s;

    .titleSection {
      grid-area: title;
      display: flex;
      justify-content: space-between;
      p {
        display: flex;
        align-content: baseline;
      }
    }
    .infoSection {
      grid-area: info;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.deviation {
  margin-left: $spacing-s;
  color: $red;
}

.actions {
  display: flex;
  margin-top: $spacing-l;

  .button {
    margin-right: $spacing-l;
  }

  @include small-screen {
    display: block;
    .button {
      margin-right: 0;
      margin-top: $spacing-s;
      text-align: center;
    }
  }
}

.addModal {
  max-height: 85vh !important;
}

.returnModal {
  overflow: visible !important;

  & > div {
    overflow: visible !important;
  }
}
