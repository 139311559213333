@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";
@import "../../../style/mixins.scss";

.section {
  margin-top: $spacing-m;
  @include medium-screen {
    margin-top: 0;
  }
  h4 {
    @extend %sectionTitle;
  }
}
