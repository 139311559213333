@import "../../style/colors.scss";
@import "../../style/mixins.scss";
@import "../../style/spacing.scss";
@import "../../style/typography.scss";

.buttons {
  margin-top: $spacing-xl;

  button {
    margin-right: $spacing-l;
  }
}

.page {
  .client {
    margin-top: -$spacing-m;
    padding-bottom: $spacing-s;
  }
}

.pageContent {
  display: grid;
  grid-template-columns: 24rem minmax(0, 1fr);
  gap: 32px;
  min-height: 50vh;

  .navigation {
    background: $pageSubMenu;

    a {
      color: $darkGrey;
    }

    ul {
      padding: 8px 0;
    }

    .navButton {
      padding: 4px 16px;
    }
    .active {
      color: $affordance;
    }
  }

  @include small-screen {
    grid-template-columns: minmax(0, 1fr);
    min-height: auto;

    .navigation {
      ul {
        padding: 0;
        display: flex;
        flex-flow: wrap;
      }
    }
  }
}

.checkboxGroup {
  margin: $spacing-m;
}

.title {
  @extend %sectionTitle;
}

.text {
  margin-bottom: $spacing-s;

  span {
    display: block;
  }

  .label {
    font-weight: $fontWeightLight;
  }
}

.inline {
  display: flex;
  align-items: center;

  span:first-child {
    margin-right: $spacing-s;
  }
}

.textTitle {
  font-weight: $fontWeightLight;
  width: 8rem;
  margin-right: $spacing-xl;
  .text {
    font-weight: $fontWeightNormal;
  }
}

.participantInfo {
  margin-top: $spacing-m;

  .row {
    display: flex;
  }
  .participantText {
    display: grid;
    grid-template-columns: 1fr;
    .text {
      margin: 0;
    }
  }
}

.participantDetails {
  .participantText {
    display: grid;
    grid-template-columns: 1fr;
    .text {
      margin: 0;
    }
  }
}

.emptyBlock {
  height: 5rem;
}
section.split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: $spacing-l;
  column-gap: $spacing-l;

  .column {
    display: flex;
    flex-direction: column;

    & > span {
      flex-grow: 1;
    }
  }
}

section.description {
  margin: $spacing-m 0 $spacing-l;
}

.editableContact {
  display: flex;
  justify-content: flex-start;

  .buttonWrapper {
    padding: $spacing-l $spacing-m;
    margin-top: $spacing-l;
  }
}

.nextMeeting {
  .split {
    padding-top: $spacing-l;
  }

  .dateTimeWrapper {
    display: flex;
  }

  .inputInline {
    flex-grow: 0;
    width: 18rem;
    margin-right: $spacing-m;
  }

  .widthAuto {
    width: auto;
  }

  .labelGrid {
    display: grid;
    grid-template-columns: 70px 250px;
  }
}

.previousMeeting {
  .split {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .icon {
    margin-right: $spacing-xs;
  }

  .columnWidth {
    width: $width-m;
  }

  td {
    padding: $spacing-s;
    vertical-align: top;

    .info {
      display: flex;
      align-items: center;
      padding: $spacing-s 0;

      span {
        color: $lightGrey;
        margin-right: $spacing-m;
      }
    }
    p {
      display: flex;
      align-items: center;
    }

    .roomAndCar {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 5rem;
    }
  }

  .status {
    display: flex;
    justify-content: flex-end;
  }

  .participant {
    display: grid;
    grid-template-columns: min-content max-content;
    padding: $spacing-s 0;

    span {
      color: $lightGrey;
      margin-right: $spacing-m;
    }
  }

  .button {
    margin: 0 0 $spacing-s $spacing-m;
  }
}

.selector {
  grid-template-columns: $defaultFieldWith;
  margin: $spacing-s;
}

.radioButtonGroup {
  display: flex;
  margin-bottom: $spacing-m;
}
.address {
  font-style: normal;
  min-width: 20rem;
}
.hul {
  margin-left: 1rem;
  font-style: normal;
  min-width: 20rem;
}

.card {
  margin-bottom: $spacing-xs;
  padding: $spacing-xs 0;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .icon {
    margin-right: $spacing-s;
  }

  .row {
    display: flex;
  }
}

.divider {
  border-bottom: 1px solid $lightGrey;
}

.sequenceBlock {
  margin: $spacing-m 0;
  .sectionTitle {
    margin: $spacing-s 0;
    width: max-content;
    font-weight: 500;
  }
  .sequenceTitle {
    font-weight: $fontWeightLight;
    margin-right: $spacing-xs;
    display: block;
  }
}

section.message {
  margin-bottom: 1rem;
}

.textarea {
  border: solid 1px $primary;
  border-radius: $borderRadius;
  min-height: 7rem;
  padding: $spacing-m;
  outline: none;

  &:focus {
    background: rgba(0, 0, 0, 0.02);
  }
}

.action {
  text-align: right;
  margin: $spacing-l 0;

  & > * {
    margin: 0 1rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .limit {
    font-size: $fontSizeSmall;
  }
}

.orderAndcompletedDate {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.warningText {
  display: flex;
  justify-content: center;
  font-size: $fontSizeSmall;
  font-style: italic;
}

.hidden {
  visibility: hidden;
  background: green;
}

.show {
  background: red !important;
}

.fittingModal {
  overflow: visible !important;

  & > div {
    overflow: visible !important;
  }
}

.form {
  .selectField {
    & > ul {
      max-height: 25rem;
    }
  }
}

.modal {
  max-height: 85vh !important;
}

.place {
  margin-right: 1rem;
}
