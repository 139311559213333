@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";
@import "../../../style/colors.scss";
@import "../../../style/sizes.scss";
@import "../../../style/mixins.scss";

.title {
  display: flex;
  justify-content: space-between;

  .contentTitle {
    @extend %pageTitle;
  }

  .inventoryStatus {
    font-weight: $fontWeightLight;
  }
  .buttons {
    display: flex;
    .button {
      margin-right: $spacing-m;
    }
  }

  @include medium-screen {
    display: block;

    .buttons {
      justify-content: flex-end;
    }
  }
  @include small-screen {
    .buttons {
      justify-content: center;
    }
  }
}

.page {
  margin-bottom: 0;
}

.search {
  display: grid;
  grid-template-columns: 1.5fr 5fr;
  column-gap: $spacing-s;
  padding-bottom: $spacing-s;

  &.center {
    align-items: center;
  }
  .baseStorageSelect {
    width: unset;
  }

  .button {
    margin-left: $spacing-xl;
  }
  .noErrorField {
    input {
      max-height: 56px;
    }
    p {
      display: none;
    }
  }

  @include medium-screen {
    grid-template-columns: 1fr 2fr;
    column-gap: $spacing-s;
  }

  @include small-screen {
    grid-template-columns: 1fr;
    column-gap: $spacing-s;

    .baseStorageSelect {
      margin-top: $spacing-s;
      width: 90vw;
    }

    .input {
      width: 90vw;
    }
  }
}

.radioButtons {
  margin-bottom: $spacing-s;
}

.bottomBorder {
  border-bottom: 1px solid $borderColorLight;
}

.label {
  margin-bottom: $spacing-s;
  span {
    margin-right: $spacing-s;
  }

  .labelTitle {
    font-weight: $fontWeightLight;
  }
}

.inline {
  display: inline-block !important;
  width: 4.8rem;
  text-align: center;
}

.item {
  border-bottom: 1px solid $borderColorLight;
  padding: $spacing-m;
  .section {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr;

    .itemBalance {
      grid-column: 4;
    }

    @include medium-screen {
      grid-template-columns: 2fr 2fr;
      .column {
        margin-bottom: $spacing-s;
      }
    }
    @include small-screen {
      grid-template-columns: 1fr;
    }
  }
}

.reportQuantity {
  margin-left: 6rem;
  display: grid;
  grid-auto-columns: 1fr;

  .reportInput {
    display: flex;

    .text {
      font-weight: $fontWeightLight;
      margin-right: 1rem;
      margin-bottom: 0;
    }

    .diff {
      color: $orange;
    }

    .ok {
      color: $greenDark;
    }
  }
  @include medium-screen {
    margin-left: 0;
    .reportInput {
      align-items: center;
    }
  }
}
/* Hide arrows for number input:  Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows for number input: Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.count {
  appearance: none;
  height: 3.2rem;
  width: 5rem;
  text-align: center;
  border: 2px solid $affordance;
  margin-right: $spacing-m;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
