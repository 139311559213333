@import "../../../../style/colors.scss";
@import "../../../../style/spacing.scss";

.container {
  margin-bottom: $spacing-xxl;
  p {
    margin-bottom: 0;
  }
}
.title {
  border-bottom: 1px solid $primary;
  width: fit-content;
}

.address {
  font-style: normal;
  margin-bottom: $spacing-xl;
  span {
    display: block;
  }
}
