@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/mixins.scss";

.modal {
  @include not-small-screen {
    max-height: min(90vh, 70rem) !important;
  }
  .contactModal {
    .info {
      font-style: italic;
      font-weight: $fontWeightLight;
      .infoTitle {
        font-weight: $fontWeightMedium;
      }
    }
    .form {
      display: flex;
      flex-direction: column;

      .saveBtn {
        margin-left: auto;
      }
    }

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: $spacing-s;
    }
  }
}
