@import "../../style/mixins.scss";
@import "../../style/spacing.scss";

.page {
  .welcomeSegment {
    margin-bottom: 4.8rem;
  }

  @include medium-screen {
    .welcomeSegment {
      margin-bottom: 6.4rem;
    }
  }
}
