@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.title {
  @extend %sectionTitle;
}

.button {
  margin-top: $spacing-m;
}
