@import "../../style/colors.scss";
@import "../../style/mixins.scss";
@import "../../style/spacing.scss";
@import "../../style/typography.scss";

.emptyHeader {
  border: 1px solid $primary;
  padding: $spacing-m;
  border-bottom: none;
}

%gridProps {
  display: grid;
  grid-template-columns: 4rem 1fr 15rem 15rem;
  grid-auto-rows: minmax(4rem, auto);
  border: 1px solid $primary;
  width: 100%;
}

%gridItemProps {
  margin-bottom: 0;
  padding: $spacing-s 0 $spacing-s $spacing-m;
}

.checkboxListHeader {
  @extend %gridProps;
  align-items: baseline;
  border-bottom: none;

  > * {
    @extend %gridItemProps;
    font-weight: $fontWeightMedium;
  }

  .headerCheck {
    position: relative;
    height: 100%;
    .check {
      position: absolute;
      top: $spacing-s;
    }
  }
  .header {
    word-break: break-all;
    &.holding {
      display: none;
    }
  }
}

.checkboxList {
  @extend %gridProps;
  max-height: 40rem; //TBD
  overflow: auto;
  margin-bottom: $spacing-xl;
  > * {
    @extend %gridItemProps;
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid silver;
  }

  .selected {
    background: $lightWhite;
  }

  .lineTitle {
    display: none;
  }

  .counter {
    padding-right: $spacing-l;
  }

  .checkWrapper {
    position: relative;
    .check {
      position: absolute;
      top: calc(50% - 12px);
    }
  }
}

@include medium-screen {
  .listTitle {
    display: none;
  }
  .checkboxListHeader {
    grid-template-columns: 4rem minmax(0, 1fr);

    .name,
    .inventory,
    .product,
    .quantity {
      display: none;
    }

    .headerCheck {
      padding: 8px;
    }
    .header {
      &.holding {
        padding-left: 0;
        display: block;
      }
    }
  }

  .checkboxList {
    grid-template-columns: 4rem minmax(0, 1fr);
    grid-auto-rows: minmax(0, auto);
    grid-auto-flow: column;

    .checkWrapper {
      grid-column: 1;
      grid-row: span 3;
      padding: $spacing-s;
      .check {
        top: $spacing-s;
      }
    }
    .description,
    .productNo,
    .inventoryNo {
      grid-column: 2;

      border: none;
      padding: 0;
      padding-right: $spacing-m;
    }
    .lineTitle {
      display: inline;
    }

    .productNo {
      padding-bottom: $spacing-s;
      border-bottom: 1px solid silver;
    }

    .description {
      padding-top: $spacing-s;
      font-weight: 500;
    }
  }
}

.textarea {
  width: 50%;
  margin: $spacing-xl 0;
  resize: none;

  @include small-screen {
    width: 100%;
  }
}
