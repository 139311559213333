@import "../../../../style/spacing.scss";
@import "../../../../style/mixins.scss";

.container {
  padding: $spacing-s;

  .tabItem {
    margin-bottom: $spacing-m;
  }

  .row {
    display: grid;
    grid-template-columns: 0.5fr 4fr;
    height: 6rem;
    align-items: center;

    .image {
      height: 5rem;
    }

    @include medium-screen {
      grid-template-columns: 1fr 4fr;
    }
  }
}
