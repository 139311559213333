@import "../../../style/colors.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";
@import "../../../style/mixins.scss";

.accordion {
  border-top-style: solid;

  border-color: $borderColor;
  border-width: 1px;
  &:last-child {
    border-bottom-style: solid;
  }
  .header {
    display: flex;
    padding: $spacing-m;
    border-style: unset;
    align-items: center;
    width: 100%;
    background-color: unset;

    &:focus {
      outline-style: unset;
    }

    .checkIcon {
      min-width: 2.4rem;
      min-height: 2.4rem;
    }
    .title {
      font-size: $baseFontSize;
      font-weight: $fontWeightMedium;
      text-align: left;
      margin-right: $spacing-l;
      margin-bottom: 0;
    }
    .expandIcon {
      margin-left: auto;
    }
  }

  .content {
    padding: $spacing-m;
  }
}
