@import "../../style/spacing.scss";
@import "../../style/sizes.scss";
@import "../../style/colors.scss";
@import "../../style/typography.scss";

$inputHeight: 46px;

.page {
  width: 100%;
}

table {
  margin: $spacing-m 0;
  width: 100%;
  border-collapse: collapse;

  .checkbox {
    width: $width-xs;
  }
  .details {
    width: $width-m;
  }
  .meetingDetails {
    width: $width-l;
  }

  tbody {
    width: 100%;
  }
  th {
    padding: $spacing-s;
    text-align: left;
  }
  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  td {
    padding: $spacing-s;
    vertical-align: top;

    .info {
      display: flex;
      align-items: center;
      padding: $spacing-s 0;
      height: $inputHeight;
    }
    a {
      font-weight: $fontWeightMedium;
    }

    .singleRow {
      padding: $spacing-xs;
      height: $inputHeight;
      & > div {
        display: flex;
        align-items: center;
      }
    }

    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: $spacing-xs;
      height: $inputHeight;

      & > div {
        display: flex;
        align-items: center;
      }
    }
  }

  .icon {
    margin-right: $spacing-xs;
  }
}

.selectAll {
  margin-bottom: $spacing-l;
}

.statusDescription {
  margin-bottom: $spacing-m;
  font-style: italic;
}

.filter {
  margin-bottom: $spacing-m;
}

.actionButtons {
  button {
    margin-right: $spacing-m;
  }
}

.releaseModal {
  max-height: 85vh !important;
}

.cleanSpacing {
  justify-content: start !important;
}

.label {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-m;
  align-items: center;
  width: 100%;

  .title {
    color: $lightGrey;
    margin-right: $spacing-m;
  }
}

.title {
  color: $lightGrey;
  margin-right: $spacing-m;
}

.consultantMargin {
  margin-right: 2.6rem;
}

.input {
  margin: 0;
  padding: 0;

  input {
    max-height: $inputHeight;
    min-height: auto !important;
    padding: 1rem !important;
  }

  p {
    display: none;
  }
}
.fieldIcons {
  top: 1.1rem;
  right: 3.5rem;
}

.detail {
  padding-top: 2rem;
}

.noResults {
  text-align: center;
}

.participantLabel {
  width: $spacing-m;
}
.completedStatus {
  width: $width-xs;
  text-align: right;
}

.fittingCompleted {
  text-align: right;
}
