@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/colors.scss";
@import "../../../style/typography.scss";

.accessories {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $spacing-m $spacing-xxl;
  grid-auto-rows: minmax(18rem, auto);

  .item {
    margin-bottom: 0;
  }

  .submitBtn {
    margin-top: $spacing-m;
    grid-column: span 3;
    width: fit-content;
  }

  @media (max-width: 1440px) {
    grid-template-columns: 1fr 1fr;

    .submitBtn {
      grid-column: span 2;
    }
  }

  @include small-screen {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;

    .submitBtn {
      grid-column: span 1;
    }
  }
}

.title {
  margin-top: $spacing-m;
  @extend %sectionTitle;
  margin-bottom: $spacing-l;
}

.specificAccessory {
  border-top: 1px solid $borderColorLight;
}

.lists {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $spacing-xl;
}
