@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.login {
  display: flex;
  flex-direction: column;
  text-align: left;
  .title {
    @extend %sectionTitle;
    margin-bottom: $spacing-m;
  }
  .input {
    width: inherit;
  }

  .messageBox {
    margin-bottom: $spacing-s;
  }
  .loginButton {
    margin: $spacing-s 0;
  }

  @include small-screen {
    align-items: center;
    width: 100%;
  }
}

.actionBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: $spacing-l;

  .passwordButton {
    margin: $spacing-m 0;
  }
}
