@import "../../../../style/spacing.scss";
@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-l $spacing-xxl;
  padding-top: $spacing-xl;
  border-top-style: solid;
  border-width: 1px;
  border-color: $lightGrey;

  @include medium-screen {
    gap: $spacing-xxl;
    margin-top: 0;
    margin-bottom: $spacing-l;
    border-top-style: unset;
  }

  .messageBox {
    grid-column: span 2;
    display: flex;
    align-items: center;

    .messageButton {
      margin-left: $spacing-m;
      color: $black;
      background: transparent;
      border: 1px solid $grey;
    }
  }

  .inline {
    display: flex;
    justify-content: space-between;
  }

  .baseStorageReceiver {
    grid-column: 1 / 3;
  }
}
