@import "../../../../style/typography.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/mixins.scss";

.list {
  columns: 2;
  margin-bottom: $spacing-xl;
  @include medium-screen {
    margin-bottom: $spacing-s;
  }
  .title {
    font-weight: $fontWeightMedium;
  }
}
