@import "../../../style/spacing.scss";

.buttons {
  display: flex;
  margin-top: $spacing-xl;

  button {
    margin-right: $spacing-l;
  }
}
