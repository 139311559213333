@import "../../style/spacing.scss";

.list {
  position: relative !important;
}

.amountToScrap {
  margin: $spacing-l 0 $spacing-l 0;
  display: flex;
  align-items: center;

  .availableBalance {
    margin-left: $spacing-s;
  }

  .counter {
    margin-left: $spacing-xl;
  }
}
