@import "../../../../style/spacing.scss";
@import "../../../../style/colors.scss";

.commentCard {
  border-top: 1px solid $borderColorLight;
  padding: $spacing-m $spacing-m $spacing-s $spacing-m;

  &:nth-last-child(2) {
    border-bottom: 1px solid $borderColorLight;
  }

  &:nth-child(odd) {
    background: $primaryLight2;
  }

  .header {
    font-size: small;
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-s;

    .author {
      font-weight: 600;
    }

    .timestamp {
      font-style: italic;
    }

    .right {
      display: flex;
      align-items: center;

      & > * {
        margin: 0 10px;
      }
    }
  }
}
