@import "../../../style/spacing.scss";
@import "../../../style/colors.scss";

.description {
  border-bottom: 1px solid $borderColorLight;
  margin-bottom: $spacing-l;
  padding-bottom: $spacing-s;
}

.itemSection {
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-bottom: 1px solid $borderColorLight;
  margin-bottom: $spacing-m;
  padding-bottom: $spacing-s;

  .amountToMove {
    margin: 0 0 $spacing-l 0;
    display: flex;
    justify-content: flex-end;

    .availableBalance {
      margin-left: $spacing-s;
    }

    .counter {
      margin-top: 0;
      margin-left: $spacing-xl;
    }
  }
}

.warehouseLocations {
  display: flex;
  justify-content: space-evenly;
}

.list {
  position: relative !important;
}
