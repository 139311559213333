@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/sizes.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/z-index.scss";

.menuWrapper {
  position: relative;
  padding: 0 $spacing-m;
  border-right: 1px solid $borderColorLight;

  .logo {
    height: 4.8rem;
    width: 4.8rem;
    margin-right: $spacing-m;
  }
  .recieverInfo {
    margin-bottom: 0;
    text-align: left;
    .orderId {
      display: block;
      font-weight: $fontWeightLight;
    }
  }

  .icon {
    margin-left: $spacing-m;
  }

  .submenuWrapper {
    position: absolute;
    right: -8px;
    padding-top: 12px;
    min-width: 300px;

    @include small-screen {
      right: 0;
    }

    .userMenu {
      background: $navigationSubMenu;
      z-index: $flyout;
      position: relative;
      border-radius: 2px;

      @include boxShadow(1);

      &::before {
        position: absolute;
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 12px solid $navigationSubMenu;
        top: -12px;
        right: 0;
      }

      .orderMenu {
        max-height: 70vh;
        overflow-y: auto;
        padding-top: unset;
        @include small-screen {
          max-height: 55vh;
        }
      }

      ul {
        padding: $spacing-m;
      }

      small {
        padding: 0 $spacing-m $spacing-m $spacing-m;
      }

      li {
        padding: $spacing-s;

        a {
          color: inherit;
          text-decoration: none;
          &:hover {
            color: $affordance;
          }
        }
        > * {
          width: 100%;
        }
      }

      .row {
        cursor: pointer;
      }

      .divider {
        display: block;
        margin: 0 $spacing-s;
        padding-top: $spacing-m;
        border-top: 1px solid $lightGrey;
        color: $lightGrey;
      }

      .receiver {
        font-weight: $fontWeightMedium;
        margin: 0;
      }

      .order {
        font-weight: $fontWeightLight;
      }

      .menuLinkItem {
        padding: $spacing-s;
      }

      .menuButtonItem {
        padding: $spacing-s;
        width: 100%;
      }
    }
  }
}
