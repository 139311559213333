@import "../../style/mixins.scss";
@import "../../style/sizes.scss";
@import "../../style/spacing.scss";
@import "../../style/typography.scss";

.userPage {
  align-items: flex-start;
  width: 100%;

  .title {
    @extend %sectionTitle;
    display: block;
    margin-bottom: $spacing-l;
  }

  .form {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
      margin-bottom: $spacing-l;
    }
    .input {
      max-width: $defaultFieldWith;
    }

    .boxContent {
      max-width: $defaultFieldWith;
    }

    .btn {
      align-self: flex-end;
    }
  }

  .password {
    .section {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      color: $borderColor;
      padding: $spacing-m 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom-style: unset;
      }
      .header {
        font-weight: $fontWeightMedium;
      }
      .text {
        font-weight: $fontWeightLight;
      }
      .button {
        align-self: center;
        justify-self: end;
        margin-top: $spacing-m;
      }
    }
  }

  @include small-screen {
    .title {
      grid-column: 1;
    }
    .form .input {
      max-width: 100%;
    }
  }
}
