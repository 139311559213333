@import "../../style/spacing.scss";
@import "../../style/colors.scss";
@import "../../style/sizes.scss";

.button {
  margin-top: $spacing-l;
  border-radius: 2rem !important;
}

.container {
  min-height: 30rem;
  .section {
    min-height: 10rem;
  }
}

.input {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.evaluateContainer {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  margin-top: $spacing-l;

  .title {
    border-bottom: $borderRadiusSmall solid $primary;
  }
}

.pageContent {
  padding-left: 20rem;
  padding-right: 20rem;
  .section {
    min-height: 20rem;
  }
}

.fileLoader {
  display: grid;
  grid-template-columns: 1fr 8fr;
  margin-top: $spacing-l;
}

.breadcrumb {
  padding: 0 1.6rem;
  margin: 0 auto;
  max-width: 152rem;
  margin-bottom: $spacing-s;
}

.file {
  display: flex;
  align-items: center;
  padding: $spacing-m 0;
  margin-top: $spacing-l;

  .icon {
    margin-right: $spacing-s;
  }

  .link :hover {
    cursor: pointer;
  }
}
