@import "../../style/spacing.scss";
@import "../../style/typography.scss";
@import "../../style/colors.scss";

.search {
  display: grid;
  grid-template-columns: 4fr 1fr;
  column-gap: $spacing-l;
  padding-bottom: $spacing-l;

  .button {
    margin-top: 1em;
  }
}

.baseSettings {
  display: grid;
  grid-template-columns: 1.5fr 5fr;
  column-gap: $spacing-s;

  .referenceSearch {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  input {
    height: 56px !important;
  }
}

.borderTop {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.deliveryRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;

  .container {
    display: grid;
    grid-column-gap: $spacing-l;
    grid-template-columns: 4fr 1fr;

    .button {
      margin-top: 3.5rem;
    }
  }
}

.deliveryItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $spacing-s;
}

.cleanSpacing {
  justify-content: start !important;
}
