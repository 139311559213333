@import "../../../style/colors.scss";
@import "../../../style/defaults.scss";
@import "../../../style/spacing.scss";

.checkbox {
  display: block;
  position: relative;
  padding-left: 2.8rem;
  cursor: pointer;
  user-select: none;
  color: currentColor;
  &.disabled {
    @extend %disabledProps;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
  }

  .checkmark {
    border: 1px solid currentColor;
    position: absolute;
    top: 4px;
    left: 0;
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 3px;
    display: flex;

    &.checked {
      border-color: $primary;
      background-color: $primary;
      color: $white;
    }
  }

  &:hover:not(.disabled) {
    color: $affordance;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
}
