@import "../../../style/spacing.scss";
@import "../../../style/colors.scss";
@import "../../../style/typography.scss";

.header {
  margin-top: $spacing-s;
  margin-bottom: $spacing-s;
  font-weight: $fontWeightMedium;
}
.inventoryNumber {
  display: flex;
  p {
    margin-right: $spacing-m;
  }
  &:hover {
    color: $affordance;
  }
}
.listAccessory {
  padding-top: $spacing-s;
  padding-bottom: $spacing-s;
  color: $lightGrey;
}
