@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/sizes.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/z-index.scss";

.menuWrapper {
  position: relative;
  padding: 0 $spacing-m;
  border-right: 1px solid $borderColorLight;

  .logo {
    height: 4rem;
    margin-right: $spacing-s;
  }
  .recieverInfo {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 0;
    text-align: left;

    .orderId {
      font-weight: $fontWeightLight;
    }
    .smallerIdentityNumber {
      font-size: $fontSizeSmall;
    }
  }

  .icon {
    margin-left: $spacing-s;
  }

  .submenuWrapper {
    position: absolute;
    right: -8px;
    padding-top: 12px;

    .userMenu {
      background: $navigationSubMenu;
      z-index: $flyout;
      padding: $spacing-m;
      position: relative;
      border-radius: 2px;
      @include boxShadow(1);

      &::before {
        position: absolute;
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 12px solid $navigationSubMenu;
        top: -12px;
        right: 0;
      }

      li {
        padding: $spacing-s;
        a {
          color: inherit;
          text-decoration: none;
          &:hover {
            color: $affordance;
          }
        }
        > * {
          width: 100%;
        }
      }

      .menuLinkItem {
        padding: $spacing-s;
      }

      .menuButtonItem {
        padding: $spacing-s;
        width: 100%;
      }
    }
  }
  .menu {
    min-width: 200px;
  }
}
