.spinner {
  width: max-content;
  margin: 10rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .spinnerImage {
    width: 60px;
    margin-bottom: 24px;
  }
}
