@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";

.sideMenu {
  background: white;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 2;
  width: 50rem;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 15px;

  @include small-screen {
    height: calc(100% - #{$mobileMenuHeight});
    width: 100%;
  }
}
