@import "../../../style/typography.scss";
@import "../../../style/spacing.scss";
@import "../../../style/sizes.scss";
@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.contentTitle {
  @extend %sectionTitle;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  border-bottom: 1px solid $lightGrey;

  .quantityInfo {
    display: flex;
    justify-content: flex-end;
  }

  @include medium-screen {
    grid-template-columns: 1fr;
    justify-content: center;
    .title {
      display: flex;
      justify-content: center;
    }
    .quantityInfo {
      justify-content: center;
    }
  }
}

.reserved {
  text-transform: lowercase;
  font-weight: $fontWeightLight;
}
.subPage {
  margin: 0;
}

.outerSection {
  display: grid;
  grid-template-columns: 5fr 5fr;
}
.information {
  width: 60rem;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .column {
    &.grow {
      flex: 1;
    }

    &.w250 {
      width: 250px;
    }
  }
  .leftSection {
    width: $width-m;
  }
  .rightSection {
    margin-left: $spacing-l;
  }
}

.description {
  margin: $spacing-l 0;
  font-weight: $fontWeightLight;
}

.infoSection {
  display: flex;
  margin-bottom: $spacing-m;

  .productInformation {
    margin-bottom: $spacing-s;
  }
  @include medium-screen {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.newSection {
  margin-top: $spacing-m;
}

.productImage {
  margin-left: $spacing-l;
  margin-bottom: $spacing-m;
  flex: 1;

  @include medium-screen {
    margin-left: 0;
  }
}

.highlightedYellow {
  color: $yellow;
}

.deviation {
  margin-left: $spacing-s;
  color: $red;
  position: absolute;
}

.printLabel {
  margin-top: $spacing-m;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: $spacing-m;

  @include medium-screen {
    margin-bottom: $spacing-s;
  }
}
