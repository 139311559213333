@import "../../../style/spacing.scss";
@import "../../../style/mixins.scss";

.container {
  .selector {
    margin-bottom: $spacing-l;
  }
  .actionContainer {
    width: 60%;
    min-height: 38rem;
    @include medium-screen {
      width: 100%;
    }
  }
}
