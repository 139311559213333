@import "../../style/colors.scss";
@import "../../style/mixins.scss";

.pageContent {
  display: grid;
  grid-template-columns: 24rem minmax(0, 1fr);
  gap: 32px;
  min-height: 50vh; //TBD

  .logout {
    border-top: 1px solid $borderColorLight;
  }

  @include small-screen {
    grid-template-columns: minmax(0, 1fr);
    min-height: auto;
    .logout {
      display: none;
    }
  }
}
