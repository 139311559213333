@import "../../style/sizes.scss";
@import "../../style/spacing.scss";
@import "../../style/mixins.scss";

.content {
  width: $defaultFieldWith;
  @include small-screen {
    width: unset;
  }

  .submitBtn {
    margin-top: $spacing-xxl;
  }
}
