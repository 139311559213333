@import "../../../../style/mixins.scss";
@import "../../../../style/sizes.scss";
@import "../../../../style/spacing.scss";

.container {
  min-height: calc(#{$defaultFieldHeight} + 12rem);
  .picker {
    max-width: $defaultFieldWith;
    @include small-screen {
      max-width: 100%;
    }
  }
  .address {
    margin: $spacing-m;
    font-style: normal;
    min-width: 20rem;
    p {
      margin: 0;
    }
  }
}
