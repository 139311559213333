@import "../../../../style/mixins.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/colors.scss";
@import "../../../../style/typography.scss";

.searchField {
  display: flex;
  align-items: center;

  .input {
    height: 60px !important;
  }
  .button {
    margin-left: $spacing-m;
  }
}

.row {
  border-top: 1px solid $borderColorLight;
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: $spacing-s 0 $spacing-s $spacing-s;

  &:last-child {
    border-bottom: 1px solid $borderColorLight;
  }

  .button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
