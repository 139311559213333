@import "../../../style/colors.scss";
@import "../../../style/defaults.scss";
@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";

.text {
  margin: 0;
  display: inline;
}

.select {
  width: 9rem;
  margin: 0 0 $spacing-s;

  input {
    text-align: right !important;
    padding-right: 4rem !important;
    outline: none !important;
  }
}

.buttons {
  display: inline;
  @include small-screen {
    display: block;
  }
  .pageBtn {
    display: inline-flex;
    border: 1px solid $borderColorLight;
    padding: 0 $spacing-s;

    &:not(:last-child) {
      border-right: none;
    }
    &:first-child {
      border-radius: $borderRadiusSmall 0 0 $borderRadiusSmall;
    }
    &:last-child {
      border-radius: 0 $borderRadiusSmall $borderRadiusSmall 0;
    }

    &.pressed {
      background-color: #eee;
      &:hover {
        color: currentColor;
      }
    }
  }
}
