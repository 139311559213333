@import "../../../style/typography.scss";
@import "../../../style/spacing.scss";
@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.deviation {
  margin-left: $spacing-s;
  color: $red;
}
.productDetail {
  .titleSection {
    margin-bottom: $spacing-xs;
    border-bottom: 1px solid $borderColorLight;
    display: flex;
    justify-content: space-between;

    .title {
      @extend %pageTitle;
      border: none;
      display: flex;
      align-items: center;
    }
    .quantity {
      @extend %pageTitle;
    }
  }
  .infoGrid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  .imageSection {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
  }
  .productNumber {
    font-weight: $fontWeightLight;
    margin-bottom: $spacing-xl;
  }
  .information {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  .informationMargin {
    margin-top: $spacing-l;
    margin-bottom: $spacing-xl;
  }
  .text {
    font-weight: $fontWeightLight;
    white-space: break-spaces;

    @include medium-screen {
      font-weight: $fontWeightNormal;
      max-width: unset;
    }
  }
}

.divider {
  padding-top: $spacing-s;
}

.printLabel {
  margin-bottom: $spacing-s;
}

.buttons {
  margin-bottom: $spacing-s;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: $spacing-l $spacing-xxl;
}

.reservedInfo {
  padding-top: $spacing-m;
  border-top-style: solid;
  border-width: 1px;
  border-color: $borderColorLight;
}

.title {
  border-bottom: 1px solid $borderColorLight;
  margin-bottom: $spacing-m;
}

.row {
  &.noSpacing {
    margin-top: 0;
  }

  .textTitle {
    font-weight: $fontWeightLight;
    margin-right: $spacing-xs;
  }

  .text {
    font-weight: $fontWeightNormal;
  }
}

.location {
  & p {
    font-weight: $fontWeightLight;
    margin-bottom: 0;
  }

  .text {
    font-weight: $fontWeightNormal;
  }
}
.link {
  color: $affordance;
  cursor: pointer;
}
