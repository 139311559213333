@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.versionFooter {
  margin-top: auto;
  padding-top: $spacing-xl;
  padding-bottom: $spacing-xl;

  .versionContent {
    @include content-container;
    text-align: right;
    color: $lightGrey;
  }
}

.footer {
  background-color: $lightWhite;
  margin-top: auto;
  padding-top: $spacing-xl;
  padding-bottom: $spacing-xl;

  .content {
    @include content-container;
    display: grid;
    grid-template-columns: 14rem 1fr 14rem;
    gap: $spacing-xl;

    .title {
      font-weight: $fontWeightMedium;
      text-transform: uppercase;
    }

    .text {
      display: block;
      color: inherit;
      margin-bottom: 0;
    }

    .section {
      justify-self: center;
      align-self: end;
      text-align: center;
      .textMargin {
        margin-bottom: $spacing-l;
      }
    }

    @include small-screen {
      grid-template-columns: 1fr;
      text-align: center;
      .address {
        grid-row: 2;
      }
    }
  }
}
