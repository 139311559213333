@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/sizes.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/z-index.scss";

.header {
  position: sticky;
  top: 0;
  width: 100vw;
  background-color: white;
  z-index: $header;
  border-bottom: 1px solid $darkWhite;

  .topBar {
    border-bottom: 1px solid $darkWhite;
    display: flex;
    justify-content: space-between;
    padding: 0 $spacing-m;
    align-items: center;
  }
  .logo {
    height: 4rem;
  }

  .actionBar {
    padding: 0 $spacing-s;

    .receiverMenu {
      padding: 0;
      margin-left: 0;
    }
    .orderMenuWrapper {
      margin-left: auto;
    }
    .orderMenu {
      margin-right: 0;
      border-right: none !important;
      padding: 0 0 0 $spacing-m;
    }
  }
  .displayGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .displayRight {
    display: flex;
    justify-content: flex-end;
  }

  .searchContainer {
    height: 4px;
    background: $navigationBar;
  }
}

.loggedIn {
  height: $mobileFullHeaderHeight;
}
