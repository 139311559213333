@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";
@import "../../../style/mixins.scss";

.container {
  width: 100%;
}

.title {
  @extend %sectionTitle;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include small-screen {
    grid-template-columns: 1fr;
  }
}

.text {
  margin-bottom: $spacing-m;

  span {
    display: block;
  }

  .label {
    font-weight: $fontWeightLight;
  }

  .inline {
    display: inline;
    margin-right: $spacing-s;
  }
}
