@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/spacing.scss";

.selectionList {
  padding: 0 $spacing-l;

  .selectionItem {
    padding: $spacing-l 0;
    &:not(:last-child) {
      border-bottom: 1px solid $grey;
    }

    .actionItem {
      width: 100%;
      text-align: left;
      background: none;
      border: none;
      display: flex;
      color: inherit;
      .blueText {
        color: $affordance;
        min-width: 30%;
        max-width: 30%;
      }
      &:hover {
        text-decoration: none;
        .blueText {
          color: $primaryDark;
        }
      }

      &:hover,
      &:focus {
        .blueText {
          color: $primaryDark;
        }
      }
      &:focus {
        border: 0;
        outline: 0;
      }
    }
  }

  @include small-screen {
    padding: 0;

    .selectionItem {
      padding: $spacing-m 0;

      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }

      .actionItem {
        flex-direction: column;
        .blueText {
          min-width: 100%;
        }
      }
    }
  }
}
