@import "../../../../style/spacing.scss";
@import "../../../../style/colors.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/mixins.scss";

.container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: $spacing-l $spacing-xxl;
  padding-top: $spacing-m;
  align-items: center;
  border-top-style: solid;
  border-width: 1px;
  border-color: $borderColorLight;

  @include medium-screen {
    grid-template-columns: 1fr 2fr;
    gap: $spacing-xxl;
    margin-top: 0;
    margin-bottom: $spacing-l;
    border-top-style: unset;
  }

  .messageBox {
    grid-column: span 2;
    display: flex;
    align-items: center;

    .messageButton {
      margin-left: $spacing-m;
      color: $black;
      background: transparent;
      border: 1px solid $grey;
    }
  }

  .row {
    display: flex;

    .label {
      font-weight: $fontWeightLight;
      margin-right: $spacing-xs;
    }
  }
}
