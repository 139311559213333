@import "../../../../style/spacing.scss";
@import "../../../../style/colors.scss";

.page {
  margin: 0 $spacing-s;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .generalSearch {
    display: flex;
    align-items: baseline;

    & > * {
      margin: 0 $spacing-m;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .list {
    padding: 0 $spacing-s 0 0;

    .filter {
      margin: $spacing-m 0;
    }
  }

  .messageDetail {
    border-left: 1px solid $borderColorLight;
  }
}
