@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.pageContent {
  display: grid;
  grid-template-columns: 24rem 5fr;
  gap: 32px;
  min-height: 50vh; //TBD
  width: 100%;

  .newMessage {
    border-bottom: 1px solid $borderColorLight;
    padding: $spacing-m 0 $spacing-m 0;
  }

  @include small-screen {
    grid-template-columns: minmax(0, 1fr);
    min-height: auto;
    .logout {
      display: none;
    }
  }
}

.page {
  margin: 0;
}
