@import "../../../../style/mixins.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/colors.scss";
@import "../../../../style/typography.scss";

.container {
  display: flex;
  justify-content: center;
  margin-top: 4.4rem;
}

.row {
  border-top: 1px solid $borderColorLight;
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: $spacing-s;

  &:last-child {
    border-bottom: 1px solid $borderColorLight;
  }

  .actions {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    align-items: center;

    .inputField {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .count {
        appearance: none;
        height: 3.2rem;
        width: 5rem;
        text-align: center;
        border: 2px solid $affordance;
        margin-right: $spacing-m;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .unit {
        margin-bottom: 0;
      }
    }

    .delete {
      display: flex;
      justify-content: flex-end;
    }
  }
}
