@import "../../../style/colors.scss";
@import "../../../style/spacing.scss";

.box {
  padding: $spacing-s $spacing-l;
  border-style: solid;
  border-width: 1px;
  background-color: $white;
  border-radius: 2px;
  margin-bottom: $spacing-s;

  p {
    color: $black;
    margin: 0;
  }
}

.error {
  background: transparentize($error, 0.6);
  border-color: $error;
}

.warning {
  background-color: transparentize($warning, 0.6);
  border-color: $warning;
}

.info {
  background: transparentize($info, 0.6);
  border-color: $info;
}
