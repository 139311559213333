@import "../../../style/colors.scss";

.table {
  border-collapse: collapse;
  border: 1px solid $primary;
  width: 100%;
  max-width: 100%;

  thead {
    tr {
      border-bottom: 1px solid $primary;
      text-align: left;

      th {
        padding: 1rem 2rem;
      }
    }
  }

  tbody {
    td {
      padding: 1rem 2rem;
    }
  }
}

.orderWrapper {
  overflow-x: auto;
}
