@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";

.gridLayout {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 34rem;
  column-gap: $spacing-l;

  .messageBox {
    grid-column: 1;
    margin-bottom: $spacing-m;
    margin-top: -$spacing-l;
    width: max-content;
    display: flex;
    align-items: center;
    .messageButton {
      margin-left: $spacing-m;
      color: $black;
      background: transparent;
      border: 1px solid $grey;
    }
  }

  .stepIndicator {
    grid-column: span 2;
  }

  @include medium-screen {
    grid-template-columns: minmax(0, 1fr);
    .stepIndicator {
      grid-column: 1;
    }

    .info {
      border: 1px solid $borderColorLight;
      padding: $spacing-xxl;
      margin-top: $spacing-l;
    }
  }

  @include small-screen {
    .messageBox {
      width: auto;
      .messageButton {
        margin: $spacing-s 0;
      }
    }
    .info {
      padding: $spacing-m;
    }
  }
}
