@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";

.fileUploadWrapper {
  position: relative;
  margin-bottom: $spacing-l;

  .label {
    width: max-content;
    color: $affordance;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $primaryDark;
      svg {
        fill: $primaryDark;
      }
    }

    .text {
      margin-left: $spacing-s;
    }
  }

  .inputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .selectedList {
    margin-top: $spacing-m;
    width: fit-content;

    .selected {
      display: grid;
      margin: 0 0 1rem 0;
      grid-template-columns: minmax(18rem, 1fr) 8rem;
      gap: $spacing-m;
      align-items: center;
      font-size: $fontSizeSmall;
      a {
        margin-bottom: 0;
      }
      .iconBtn {
        height: 3.2rem;
        width: 10rem;
      }
      .iconBtn:hover svg {
        fill: $affordance;
      }
    }

    @include small-screen {
      width: 100%;
      max-width: unset;
      .selected {
        grid-template-columns: minmax(18rem, 1fr) 4.4rem;
        .iconBtn {
          height: 4.4rem;
          width: 4.4rem;
        }
      }
    }
  }
}
