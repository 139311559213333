@import "../../../../style/colors.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";

.messageDetail {
  padding-left: $spacing-m;
  .header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid $borderColorLight;

    .title {
      font-weight: $fontWeightMedium;
    }
  }

  .date {
    display: flex;
    justify-content: flex-end;
  }

  .message {
    padding-bottom: $spacing-m;
    border-bottom: 1px solid $borderColorLight;

    .subject {
      font-size: $fontSizeLarge;
      margin: $spacing-m 0 $spacing-m 0;
    }
    .body {
      white-space: pre-wrap;
    }
  }

  .filesAndButton {
    margin-top: $spacing-m;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .button {
    display: flex;
    justify-content: flex-end;
    padding-top: $spacing-s;
  }
}
