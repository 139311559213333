@import "../../../style/colors.scss";
@import "../../../style/defaults.scss";
@import "../../../style/typography.scss";
@import "../../../style/spacing.scss";
@import "../../../style/mixins.scss";

.title {
  @extend %pageTitle;
  margin-bottom: $spacing-l;

  .description {
    font-weight: $fontWeightLight;
  }
  .status {
    font-weight: $fontWeightMedium;
    &.status20 {
      color: $red;
    }
    &.status30 {
      color: $lightGrey;
    }
    &.status40 {
      color: $greenDark;
    }
  }
}

.pageContent {
  display: grid;
  grid-template-columns: 24rem minmax(0, 1fr);
  gap: 32px;
  min-height: 50vh;

  .navigation {
    background: $pageSubMenu;

    a {
      color: $darkGrey;
    }

    ul {
      padding: 8px 0;
    }

    .navButton {
      padding: 4px 16px;
    }
    .active {
      color: $affordance;
    }
  }
}
@include small-screen {
  .pageContent {
    grid-template-columns: minmax(0, 1fr) !important;
    min-height: auto;

    .navigation {
      ul {
        padding: 0;
        display: flex;
        flex-flow: wrap;
      }
    }
  }
}
