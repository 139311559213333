@import "../../../../style/spacing.scss";
@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/typography.scss";

.accessories {
  .accessory {
    border-top: 1px solid $borderColorLight;
    padding: $spacing-xs 0;
    display: grid;
    grid-template-columns: 0.1fr 0.1fr 1fr;
    align-items: center;
    margin: $spacing-s 0 $spacing-s 0;

    .details {
      display: grid;
      grid-template-columns: 3fr 0.5fr;
      width: 100%;
    }

    .head {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      .title {
        white-space: nowrap;
        font-size: $fontSizeMedium;
        margin-bottom: $spacing-xs;
      }
    }
    .checkbox {
      input {
        position: unset;
      }
    }
  }

  .selected {
    display: flex;
  }

  .right {
    text-align: right;
  }

  .image {
    margin-right: $spacing-m;

    figure {
      height: 80px !important;
      width: 80px !important;
    }
  }
}
