@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";

.textarea {
  width: 50%;
  margin: $spacing-xl 0;
  resize: none;

  @include small-screen {
    width: 100%;
  }
}
