@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.productCard {
  .title {
    text-transform: uppercase;
    font-weight: $fontWeightMedium;
    margin-top: $spacing-m;
    margin-bottom: 0;
    word-break: break-word;

    @include small-screen {
      margin-top: $spacing-s;
      font-size: $fontSizeSmall;
    }
  }
  .number {
    font-size: $fontSizeSmall;
    font-weight: $fontWeightLight;
    margin: 0;
  }
}
