@import "../../style/spacing.scss";

.model {
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: $spacing-m;
    margin-bottom: $spacing-l;
  }
}
