@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.wrapper {
  overflow-x: auto;
}

.noErrorField {
  p {
    display: none;
  }
}

.isBaseStorageWorkList {
  margin: 0;
  padding: 0;
}

.filter {
  margin: $spacing-m 0;
}

.page {
  margin: 0;
}

.includeFacilitySelector {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: $spacing-l;
}

.filters {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: $spacing-l;
  margin-bottom: $spacing-l;
}

.generalSearch {
  display: flex;
  align-items: center;
  padding-bottom: $spacing-l;

  & > * {
    margin: 0 $spacing-m;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.searchGroup {
  display: flex;

  @include small-screen {
    display: block;
  }

  & > * {
    margin: 0 $spacing-m;
    flex: 1;

    @include small-screen {
      margin: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.searchButtons {
  display: flex;
  justify-content: flex-end;
  padding-top: $spacing-s;

  & > * {
    margin: $spacing-s;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.card {
  border-top: 1px solid $borderColorLight;
  padding: $spacing-s;
  display: grid;
  grid-template-columns: 2fr 2fr;

  .descriptionAndStatus {
    display: grid;
    grid-template-columns: 3fr 0.5fr;
  }
  &:last-child {
    border-bottom: 1px solid $borderColorLight;
  }

  .right {
    text-align: right;
  }

  .status {
    font-weight: $fontWeightMedium;

    &.status20 {
      color: $lightGrey;
    }
    &.status30 {
      color: $blue;
    }
    &.status50 {
      color: $orange;
    }
  }

  .bold {
    font-weight: $fontWeightMedium;
  }

  .row {
    display: flex;
  }

  .item {
    display: flex;
  }

  .grow {
    flex: 1;
  }

  .label {
    font-weight: $fontWeightLight;
    padding-right: 3px;
  }

  @include small-screen {
    grid-template-columns: 1fr;
  }
}
