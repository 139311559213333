@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.loginFormsOuterContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.loginFormContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.sectionTitle {
  @extend %sectionTitle;
}

.textAlignLeft {
  width: 300px;
  text-align: left;
}
