@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

%gridProps {
  display: grid;
  grid-template-columns: 4rem 1fr 15rem 15rem;
  grid-auto-rows: minmax(4rem, auto);
  border: 1px solid $primary;
  width: 100%;
}

%gridItemProps {
  margin-bottom: 0;
  padding: $spacing-s 0 $spacing-s $spacing-m;
}

.error {
  color: $red;
}

.right {
  text-align: right;
}

.disabled {
  color: $lightGrey;
}

.checkboxListHeader {
  @extend %gridProps;
  align-items: baseline;
  border-bottom: none;

  > * {
    @extend %gridItemProps;
    font-weight: $fontWeightMedium;
  }

  .headerCheck {
    position: relative;
    height: 100%;
    .check {
      position: absolute;
      top: $spacing-s;
    }
  }
  .header {
    word-break: break-all;
    &.holding {
      display: none;
    }
  }
}

.checkboxList {
  @extend %gridProps;
  max-height: 40rem; //TBD
  overflow: auto;
  > * {
    @extend %gridItemProps;
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $borderColorLight;

    &:nth-last-child(-n + 4) {
      border-bottom: none;
    }
  }

  .selected {
    background: $lightWhite;
  }

  .lineTitle {
    display: none;
  }

  .counter {
    padding-right: $spacing-l;
  }

  .checkWrapper {
    position: relative;
    .check {
      position: absolute;
      top: calc(50% - 12px);
    }
  }
}

@include medium-screen {
  .listTitle {
    display: none;
  }
  .checkboxListHeader {
    grid-template-columns: 4rem minmax(0, 1fr);

    .name,
    .inventory,
    .product,
    .quantity {
      display: none;
    }

    .headerCheck {
      padding: 8px;
    }
    .header {
      &.holding {
        padding-left: 0;
        display: block;
      }
    }
  }

  .checkboxList {
    grid-template-columns: 4rem minmax(0, 1fr);
    grid-auto-rows: minmax(0, auto);
    grid-auto-flow: column;

    .checkWrapper {
      grid-column: 1;
      grid-row: span 3;
      padding: $spacing-s;
      .check {
        top: $spacing-s;
      }
    }
    .description,
    .productNo,
    .inventoryNo {
      grid-column: 2;

      border: none;
      padding: 0;
      padding-right: $spacing-m;
    }
    .lineTitle {
      display: inline;
    }

    .productNo {
      padding-bottom: $spacing-s;
      &:not(:last-child) {
        border-bottom: 1px solid $borderColorLight;
      }
    }

    .description {
      padding-top: $spacing-s;
      font-weight: 500;
    }
  }
}

.selectAll {
  margin: $spacing-s;
}
.mobileSection {
  grid-area: title;
  grid-template-columns: 1fr;
}

.results {
  border-top: 1px solid $borderColorLight;

  .item {
    display: grid;
    grid-template-columns: 28px 1fr;
    grid-template-areas:
      "checkbox title"
      "checkbox info";
    border-bottom: 1px solid $borderColorLight;
    padding: $spacing-s;

    .titleSection {
      grid-area: title;
      display: flex;
      justify-content: space-between;
      p {
        display: flex;
        align-content: baseline;
      }
    }
    .infoSection {
      grid-area: info;
      display: grid;
      grid-template-columns: 3fr 1fr 1fr 3fr;
    }
  }
  @include medium-screen {
    .infoSection {
      grid-template-columns: 2fr 2fr !important;
    }
  }
  @include small-screen {
    .infoSection {
      grid-template-columns: 1fr !important;
    }
    .right {
      text-align: left;
    }
  }
}

.deviation {
  margin-left: $spacing-s;
  color: $red;
}

.actions {
  margin-top: $spacing-l;

  & > * {
    margin-right: $spacing-l;
  }
}

.highlightedYellow {
  color: $yellow;
}

.text {
  margin-bottom: $spacing-s;

  .label {
    font-weight: $fontWeightLight;
    margin-right: $spacing-xs;
  }
}
