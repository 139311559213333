@import "../../../style/colors.scss";
@import "../../../style/spacing.scss";
@import "../../../style/sizes.scss";
@import "../../../style/typography.scss";

.page {
  margin: 0;
}

.filter {
  margin: $spacing-m 0;
}

.statusDescription {
  margin-bottom: $spacing-m;
  font-style: italic;
}

td {
  padding: $spacing-s;
  vertical-align: top;

  .info {
    display: flex;
    align-items: center;
    padding: $spacing-s 0;

    span {
      color: $lightGrey;
      margin-right: $spacing-m;
    }
  }
}

.split {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.icon {
  margin-right: $spacing-xs;
}

.noResults {
  text-align: center;
}
.completedStatus {
  display: flex;
  justify-content: flex-end;
}
