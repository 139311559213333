@import "style/colors.scss";

body {
  color: $fontColor;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: $affordance;
}

.active {
  color: $primary;
}

textarea {
  resize: none;
}
