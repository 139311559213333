@import "../../style/defaults.scss";
@import "../../style/mixins.scss";
@import "../../style/spacing.scss";
@import "../../style/typography.scss";

.page {
  margin-bottom: 0 !important;
}
.layout {
  display: flex;

  .checkBox {
    position: absolute;
    right: 0;
    top: -4.8rem;
  }
  .content {
    flex: 1;
    position: relative;

    .emptyCartText {
      margin-bottom: 6rem;
    }

    footer {
      display: flex;
      margin-top: $spacing-l;
      justify-content: space-between;
      align-items: center;
    }
  }

  .info {
    width: 34rem;
    margin-left: $spacing-xxl;
  }
}

@include medium-screen {
  .layout {
    display: block;

    .info {
      border: 1px solid $contentBorder;
      width: 100%;
      padding: $spacing-xxl;
      margin-left: 0;
      margin-top: $spacing-l;
    }
  }
}

@include small-screen {
  .layout {
    .checkBox {
      top: -$spacing-l;
    }
    .info {
      padding: $spacing-m;
    }
  }
}
