@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.orderSection {
  margin-bottom: $spacing-xl;

  .title {
    border-bottom: 1px solid $primary;
    margin-bottom: 0;
  }

  .row {
    margin-top: $spacing-s;

    &.noSpacing {
      margin-top: 0;
    }
  }

  .textTitle {
    font-weight: $fontWeightLight;
    margin-right: $spacing-xs;
  }

  .questionBlock {
    margin: $spacing-m 0;
    .sectionTitle {
      margin: $spacing-s 0;
      width: max-content;
      font-weight: 500;
    }
    .questionTitle {
      @extend .textTitle;
      display: block;
    }
  }

  .text {
    display: inline-grid;
  }
}

.address {
  .textTitle {
    margin-top: $spacing-s;
  }
  .text {
    margin-bottom: $spacing-xl;
  }
}
