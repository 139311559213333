@import "../../../style/spacing.scss";
@import "../../../style/colors.scss";

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-xl;
}

.orderOverviewModal {
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    height: 75vh;
  }
}


.radioButtonGroup {
  display: flex;
  margin-bottom: $spacing-xl;
}
.cartMessageTextArea {
  width: 100%;
  color: $fontColor;
  height: 15rem;
  margin-top: $spacing-xl;
  border: 1px solid $primary;
  padding: 0.8rem 1.5rem;
  border-radius: 0.4rem;
  &:active,
  &:focus-visible {
    outline: 2px solid $primaryDark;
  }
}
