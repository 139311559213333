@import "../../../style/spacing.scss";

.price {
  padding-top: 0;
  text-align: center;
}

.totalPrice {
  text-align: center;
}
