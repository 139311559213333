@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";

.overview {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: $spacing-m 8rem;

  @include small-screen {
    grid-template-columns: minmax(0, 1fr);
    gap: $spacing-xl;
  }
}
