@import "../../../style//spacing.scss";

.container {
  display: flex;
  flex-wrap: wrap;
}

.radio {
  display: flex;
  align-items: center;
  margin-right: $spacing-xl;
  &:last-child {
    margin-right: 0;
  }
  .input {
    margin-right: $spacing-s;
    width: 16px;
    height: 16px;
  }
}
