@import "../../style/colors.scss";
@import "../../style/mixins.scss";
@import "../../style/spacing.scss";
@import "../../style/typography.scss";

.wrapper {
  overflow-x: auto;
}

.noErrorField {
  p {
    display: none;
  }
}

.filters {
  margin-bottom: $spacing-l;
}

.generalSearch {
  display: flex;
  align-items: center;
  padding-bottom: $spacing-l;

  & > * {
    margin: 0 $spacing-m;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .icon {
    min-width: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.searchGroup {
  display: flex;

  @include small-screen {
    display: block;
  }

  & > * {
    margin: 0 $spacing-m;
    flex: 1;

    @include small-screen {
      margin: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.searchButtons {
  display: flex;
  justify-content: flex-end;
  padding-top: $spacing-s;

  & > * {
    margin: $spacing-s;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.noBorder {
  border: none !important;
}

.results {
  border: 1px solid $borderColorLight;

  .row {
    border-bottom: 1px solid $borderColorLight;
    padding: $spacing-s;
    display: flex;

    @include small-screen {
      display: block;
    }

    &:last-child {
      border: none;
    }

    .column {
      flex: 1;
    }

    .label {
      font-weight: $fontWeightLight;
      padding-right: 3px;
    }

    .link {
      color: $affordance;
      cursor: pointer;
    }
  }
}
