@import "../../../../style/colors.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/typography.scss";

.results {
  border-top: 1px solid $borderColorLight;

  padding: $spacing-m $spacing-m $spacing-s $spacing-m;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  width: 100%;

  &:hover {
    background-color: $darkWhite;
    cursor: pointer;
  }

  &:nth-last-child(2) {
    border-bottom: 1px solid $borderColorLight;
  }

  .sender {
    display: flex;
    align-items: center;

    .title {
      font-weight: $fontWeightMedium;
    }

    .unread {
      display: flex;
      align-items: center;
      margin-right: $spacing-xs;
    }

    .read {
      margin-left: 2rem;
    }
  }

  .date {
    display: flex;
    justify-content: flex-end;
  }

  .subject {
    margin-left: 2rem;
  }

  .archive {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  @include medium-screen {
    grid-template-columns: 1fr;
  }
}
.active {
  background-color: $primaryLight2;
}
