@import "../../../style/colors.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.emptyList {
  @extend %defaultItemText;

  border: 1px solid $primary;
  padding: $spacing-m;
  margin-bottom: $spacing-xl;
}
