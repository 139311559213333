@import "../../style/colors.scss";
@import "../../style/mixins.scss";
@import "../../style/spacing.scss";
@import "../../style/typography.scss";

.directory {
  display: flex;
  flex-wrap: wrap;

  .folder {
    display: flex;
    align-items: center;
    padding: $spacing-m 0;
    width: 300px;

    &:hover {
      background: $lightWhite;
    }

    .icon {
      margin: $spacing-m;
      height: 40px;
      align-items: center;
      text-align: center;

      .logo {
        width: 100px;
      }
    }
  }
}

.files {
  .file {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $borderColorLight;
    padding: $spacing-m 0;

    .icon {
      margin-right: $spacing-s;
    }
  }
}
