@import "../../../style/spacing.scss";
@import "../../../style/colors.scss";
@import "../../../style/typography.scss";

.title {
  color: $primary;
  margin-bottom: $spacing-m;
}
.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $spacing-m;

  .name {
    font-weight: $fontWeightMedium;
  }
}
