@import "../../../../style/mixins.scss";
@import "../../../../style/spacing.scss";

.compactWrapper {
  grid-column: span 2;
}

.receiverLocation {
  label {
    margin-bottom: $spacing-m;
  }
  @include medium-screen {
    grid-column: span 2;
  }
}
