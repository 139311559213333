@import "../../../../style/colors.scss";
@import "../../../../style/mixins.scss";
@import "../../../../style/sizes.scss";
@import "../../../../style/spacing.scss";

.selector {
  display: grid;
  grid-template-columns: $defaultFieldWith max-content;
  grid-template-rows: auto minmax(10rem, max-content);
  gap: $spacing-s $spacing-l;
  margin-bottom: $spacing-m;

  .selectField {
    max-width: $defaultFieldWith;
  }
  .addNewBtn {
    margin-left: $spacing-xl;
    .text {
      margin-left: $spacing-s;
    }
  }

  .selected {
    display: flex;
    justify-content: space-between;
    margin-left: $spacing-m;
    max-width: $defaultFieldWith;
    .editBtn {
      margin-left: $spacing-m;
    }
  }

  @include small-screen {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-bottom: $spacing-xl;
    .addNewBtn {
      grid-row: 3;
      margin-left: 0;
    }

    .selected {
      margin: $spacing-s;
      margin-left: $spacing-m;
    }
  }
}
