.iconWrapper {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;

  img,
  svg {
    width: 100%;
    height: 100%;
  }
}
