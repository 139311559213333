@import "../../style/spacing.scss";
@import "../../style/typography.scss";
@import "../../style/colors.scss";
@import "../../style/mixins.scss";

section.search {
  display: grid;
  grid-template-columns: 2fr 5.5fr 1fr;
  column-gap: $spacing-s;
  // padding-bottom: $spacing-l;

  .filterButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: $spacing-s;
  }

  @include medium-screen {
    grid-template-columns: 1fr 2fr;
  }

  @include small-screen {
    grid-template-columns: 1fr;
  }
}

.toolBar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: $spacing-s;

  .baseStorageMoveButton {
    display: flex;
    justify-content: flex-end;
  }
}

.activeFilters {
  margin: 0 0 $spacing-m 0;
  display: flex;
}

.badge {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
  button {
    background: none;
    border: none;
    outline: none;
    margin-left: 1rem;
    padding: 0 0.5rem;
  }
}

.searchAndButtonWrapper {
  display: flex;
}

.noErrorField {
  input {
    max-height: 56px;
  }
  p {
    display: none;
  }
}

.searchButton {
  margin-top: $spacing-s;
}

.verticallyAlign {
  margin-left: $spacing-m;
  display: flex;
  align-items: center;
}

.label {
  margin-bottom: $spacing-s;
  span {
    margin-right: $spacing-s;
  }

  .labelTitle {
    font-weight: $fontWeightLight;
  }
}

.results {
  border-top: 1px solid $borderColorLight;
  .item {
    border-bottom: 1px solid $borderColorLight;
    padding: $spacing-m;
    .section {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr 1fr;

      .itemBalance {
        grid-column: 4;
      }
      @include medium-screen {
        grid-template-columns: 1fr 1fr;
      }
      @include small-screen {
        grid-template-columns: 1fr;
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-end;

      @include small-screen {
        justify-content: center;
      }

      .move {
        padding-right: $spacing-l;
      }
    }
  }
}

.deviation {
  margin-left: $spacing-s;
  color: $red;
}
