@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.aidRow {
  margin-top: $spacing-s;
  margin-bottom: $spacing-m;
  > span {
    display: block;
  }
  .subTitle {
    font-weight: $fontWeightLight;
    margin-right: $spacing-xs;
  }
}
