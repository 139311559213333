@import "../../../style/colors.scss";
@import "../../../style/defaults.scss";
@import "../../../style/spacing.scss";

.radioButton {
  position: relative;
  padding-left: 2.8rem;
  margin-right: $spacing-m;
  cursor: pointer;
  user-select: none;
  color: $fontColor;
  &.disabled {
    @extend %disabledProps;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 2px solid $grey;

    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 0.2rem;
      left: 0.2rem;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background: $grey;
    }
  }

  &:hover:not(.disabled) {
    color: $affordance;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
}
