@import "../../../style/spacing.scss";
@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.tabContainer {
  margin: $spacing-s;
  padding: 3%;
  border-radius: 4px;

  .tabTitles {
    display: flex;
    margin-bottom: $spacing-s;
    white-space: nowrap;
    overflow-x: auto;
  }

  .title {
    margin-right: $spacing-l;
    padding: $spacing-s $spacing-s $spacing-xs $spacing-s;

    &:hover {
      cursor: pointer;
    }
  }

  .content {
    margin: $spacing-m;
    background-color: aquamarine;
  }

  .active {
    border-bottom: solid 3px $primary;
  }

  @include medium-screen {
    margin: 0;
  }
}
