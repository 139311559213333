@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.contentTitle {
  @extend %sectionTitle;
}

.wrapper {
  overflow-x: auto;
}

.noErrorField {
  p {
    display: none;
  }
}

.page {
  margin: 0;
}

.filters {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: $spacing-l;
  margin-bottom: $spacing-l;
}

.generalSearch {
  display: flex;
  align-items: center;
  padding-bottom: $spacing-l;

  & > * {
    margin: 0 $spacing-m;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.searchGroup {
  display: flex;

  @include small-screen {
    display: block;
  }

  & > * {
    margin: 0 $spacing-m;
    flex: 1;

    @include small-screen {
      margin: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.searchButtons {
  display: flex;
  justify-content: flex-end;
  padding-top: $spacing-s;

  & > * {
    margin: $spacing-s;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.card {
  border-top: 1px solid $borderColorLight;
  padding: $spacing-s;

  &:last-child {
    border-bottom: 1px solid $borderColorLight;
  }

  .header {
    display: flex;

    .left {
      flex: 1;
    }

    .right {
      flex: 1;
      text-align: right;
    }

    .status {
      font-weight: $fontWeightMedium;

      &.status20 {
        color: $lightGrey;
      }
      &.status30 {
        color: $blue;
      }
      &.status50 {
        color: $orange;
      }
    }
  }

  .bold {
    font-weight: $fontWeightMedium;
  }

  .row {
    display: flex;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .item {
      display: flex;
    }
  }

  .grow {
    flex: 1;
  }

  .label {
    font-weight: $fontWeightLight;
    padding-right: 3px;
  }
}
