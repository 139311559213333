@import "../../../style/typography.scss";
@import "../../../style/spacing.scss";
@import "../../../style/mixins.scss";

.productDetail {
  .imageSection {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;

    .title {
      @extend %pageTitle;
      margin-bottom: 0;
      position: relative;
    }
  }
  .productNumber {
    font-weight: $fontWeightLight;
    margin-bottom: $spacing-xl;
  }
  .information {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  .informationMargin {
    margin-top: $spacing-m;
    margin-bottom: $spacing-xl;
  }

  .inventoryList {
    margin-top: $spacing-m;
    grid-column: 2;
  }

  .text {
    font-weight: $fontWeightLight;
    white-space: break-spaces;

    @include medium-screen {
      font-weight: $fontWeightNormal;
      max-width: unset;
    }
  }
}

.titleRow {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: $spacing-xs;
  border-bottom: 1px solid $borderColorLight;

  .title {
    @extend %pageTitle;
    margin-bottom: 0;
  }
  .price {
    @extend %pageTitle;
    margin-bottom: 0;
    text-align: right;
  }
}

.similarProducts {
  margin-bottom: $spacing-s;
}

.row {
  &.noSpacing {
    margin-top: 0;
  }

  .textTitle {
    font-weight: $fontWeightLight;
    margin-right: $spacing-xs;
  }

  .text {
    font-weight: $fontWeightNormal;
  }
}
.notOrderable {
  top: -25px;

  @include medium-screen {
    top: 0;
  }
}
