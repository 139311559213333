@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";
@import "../../../style/mixins.scss";

.content {
  .columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-m $spacing-xxl;
    grid-auto-rows: minmax(18rem, auto);
  }

  @include small-screen {
    width: unset;
  }

  .submitBtn {
    margin-top: $spacing-xxl;
  }
}
